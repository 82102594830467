import { toNumber } from "lodash-es"

export const commaDecimalSeparatorCurrencies = [
  'EUR',
  'NOK',
  'SEK',
  'DKK',
  'BRL'
]

export const taxInclusiveCurrencies = [
  'GBP',
  'EUR',
  'JPY',
  'CHF',
  'NOK',
  'SEK',
  'DKK',
  'BRL'
]

export const nonRoundedCurrencies = []

export const parseMoney = (money, currency) => {
  if (typeof money === 'string' && (money[0] < '0' || money[0] > '9')) {
    let i = 0

    while (money[i] < '0' || money[i] > '9') {
      i++
    }

    let priceString = money.substring(i)

    if (commaDecimalSeparatorCurrencies.includes(currency)) {
      priceString = priceString.replace(/\./g, '').replace(',', '.')
    } else {
      priceString = priceString.replace(',', '')
    }

    return toNumber(priceString)
  } else {
    return toNumber(money)
  }
}

export const getTax = (currency, country) => {
  switch (country) {
    case 'FI':
      return 0.24
  }

  switch (currency) {
    case 'GBP':
    case 'EUR':
      return 0.201
    case 'JPY':
      return 0.1
    case 'CHF':
      return 0.077
    case 'NOK':
    case 'SEK':
    case 'DKK':
      return 0.25
  }

  return 0
}

export const getPriceAmount = (price, currency, includeTax = true, country = Shopify.country) => {
  const amount = parseMoney(price, currency) * (1 + (includeTax ? getTax(currency, country) : 0))

  return toNumber(nonRoundedCurrencies.includes(currency) ? Math.round(amount.toFixed(2) * 2) / 2 : Math.round(amount.toFixed(2)))
}

export const numberWithThousandSeparator = (n, currency) => {
  let thousandSeparator = ','
  let decimalSeparator = '.'
  let amount = n.toString()

  if (commaDecimalSeparatorCurrencies.includes(currency)) {
    thousandSeparator = '.'
    decimalSeparator = ','
    amount = amount.replace('.', ',')
  } else {
    amount = amount.replace(',', '.')
  }

  const integer = amount.split(decimalSeparator).at(0)
  let decimal = amount.split(decimalSeparator).at(1) || ''

  if (decimal) {
    decimal = decimalSeparator + decimal + (decimal.length === 1 ? '0' : '')
  }

  return integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator) + decimal
}

const defaultCurrencyFormatter = (n) => `${n.toFixed(n % 1 === 0 ? 0 : 2)}`
const currencyFormatters = {
	USD: (n) => `$${defaultCurrencyFormatter(n)}`,
	AUD: (n) => `$${defaultCurrencyFormatter(n)} AUD`,
	NZD: (n) => `$${defaultCurrencyFormatter(n)} NZD`,
	EUR: (n) => `€${defaultCurrencyFormatter(n)}`,
	GBP: (n) => `£${defaultCurrencyFormatter(n)}`,
	JPY: (n, currency) => `¥${numberWithThousandSeparator(n, currency)}`,
	CNY: (n) => `¥${n} RMB`,
  CHF: (n) => `CHF ${defaultCurrencyFormatter(n)}`,
  HKD: (n, currency) => `$${numberWithThousandSeparator(n, currency)} HKD`,
  SGD: (n, currency) => `$${numberWithThousandSeparator(n, currency)} SGD`,
  NOK: (n, currency) => `NOK ${numberWithThousandSeparator(n, currency)}`,
  SEK: (n, currency) => `SEK ${numberWithThousandSeparator(n, currency)}`,
  DKK: (n, currency) => `DKK ${numberWithThousandSeparator(n, currency)}`,
  BRL: (n, currency) => `R$${numberWithThousandSeparator(n, currency)}`,
  _: (n) => `$${defaultCurrencyFormatter(n)}`
}

export const formatMoneyByCurrency = (amount, currency) => {
  return currencyFormatters[currency in currencyFormatters ? currency : '_'](amount, currency)
}

export const formatMoney = (amount, currency, includeTax = true, country = window.Shopify.country) => {
  if (!currency) {
    currency = window.Shopify.currency.active
  }

  return formatMoneyByCurrency(getPriceAmount(amount, currency, includeTax, country), currency)
}

export const getDiscountedPrice = (price) => {
  if (!window.gatedArchiveSale || !window.gatedArchiveSale.isEnable() || !window.gatedArchiveSale.discount.value) return price
  let discountPrice

  if (window.gatedArchiveSale.discount.type === 'percentage') {
    discountPrice = price * (1 - window.gatedArchiveSale.discount.value / 100)
  } else {
    discountPrice = price - window.gatedArchiveSale.discount.value
  }

  return discountPrice
}
