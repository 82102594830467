import Holidays from 'date-holidays'
import moment from 'moment-timezone'
import business from 'moment-business'

const cache = {}

const getHolidaysInstance = (country) => {
  if (!(country in cache)) {
    cache[country] = new Holidays(country)
  }

  return cache[country]
}

export const isHoliday = (date, country) => {
  const instance = getHolidaysInstance(country)
  const localDate = moment(date.toDate()).tz(instance.__timezone, true)
  const holidays = instance.isHoliday(localDate);

  if (!holidays) {
    return false
  }

  if (!Array.isArray(holidays)) {
    return holidays.type === 'public'
  }

  return holidays.some(holiday => holiday.type === 'public')
}

export const isCountriesHoliday = (date, countries, combinationType = 'OR') => {
  if (typeof countries === 'string') {
    return isHoliday(date, countries)
  }

  if (!Array.isArray(countries)) {
    return false
  }

  const conditions = countries.map(country => isHoliday(date, country))

  if (combinationType === 'OR') {
    return conditions.some(condition => condition)
  } else {
    return conditions.every(condition => condition)
  }
}

export const isBusinessDay = (date, countries) => {
  const isHoliday = isCountriesHoliday(date, countries)
  const isWeekday = business.isWeekDay(date)

  return isWeekday && !isHoliday
}

export const addBusinessDays = (date, amount, countries) => {
  if (amount < 1) {
    return date
  }

  const nextDate = moment(date).add(1, 'd')

  if (isBusinessDay(nextDate, countries)) {
    return addBusinessDays(nextDate, amount - 1, countries)
  } else {
    return addBusinessDays(nextDate, amount, countries)
  }
}

export const getDispatchDay = (dispatchCountry, cutoffHour) => {
  const currentTimeInSG = moment().tz('Asia/Singapore')

  if (isBusinessDay(currentTimeInSG, dispatchCountry)) {
    if (currentTimeInSG.hour() < cutoffHour) {
      return currentTimeInSG;
    }
  }

  // return addBusinessDays(currentTimeInSG, 1, dispatchCountry) ???
}

export const getShippingData = ({ default: defaultData, geoData }, countryCode) => {
  let matchedGeoData = null
  const countryCodeDown = countryCode.toLowerCase()

  if (geoData) {
    geoData.forEach(data => {
      const countryCodesDown = data.countryCodes.toLowerCase()

      if (countryCodesDown && countryCodesDown.includes(countryCodeDown)) {
        if (validateShippingData(data)) {
          matchedGeoData = data
        }
      }
    })

    if (matchedGeoData) {
      return matchedGeoData
    }

    if (validateShippingData(defaultData)) {
      return defaultData
    }

    return null
  }
}

const validateShippingData = data => {
  const hasMessage = !!data.message
  const hasStandard = data.standard && data.standard.minDeliveryDays && data.standard.maxDeliveryDays
  const hasExpress = data.express && data.express.minDeliveryDays && data.express.maxDeliveryDays

  return hasMessage && hasStandard && hasExpress
}
